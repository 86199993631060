import { postInfo } from './api';

export default {
  // 产品分类列表
  categorylist(data) {
    return postInfo('/product/category/list', data);
  },
  // 编辑产品分类
  categoryedit(data) {
    return postInfo('/product/category/edit', data);
  },
  // 删除产品分类
  categorydel(data) {
    return postInfo('/product/category/del', data);
  },
  // 新增产品分类
  categoryadd(data) {
    return postInfo('/product/category/add', data);
  },
  // 产品分类上架、下架
  categoryshelves(data) {
    return postInfo('/product/category/shelves', data);
  },
};
