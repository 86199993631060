<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :title="dialogTitle"
      width="500px"
      v-drag
      @closed="dialogClosed"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        ref="elform"
        :model="formData"
        label-width="130px"
        size="small"
        :rules="rules"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="分类名称"
              prop="cateName"
            >
              <el-input
                placeholder="请输入分类名称"
                v-model.trim="formData.cateName"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="上级分类"
              prop="parentCateId"
            >
              <el-select
                v-model="formData.parentCateId"
              >
                <el-option
                  v-for="(item, index) in firstPro"
                  :key="index"
                  :label="item.cateName"
                  :value="String(item.id)"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24">
            <el-form-item
              label="系统分配分类ID"
              prop="sign"
            >
              <el-input
                placeholder="系统分配分类ID"
                v-model.trim="formData.sign"
                readonly
              ></el-input>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small"
          @click="dialogVisible = false"
        >取消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="submitForm"
          :loading="loading"
        >保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import utils from '@/utils/utils';
import api from '@/api/ProClassifyApi';
import appApi from '@/api/app';

export default {
  name: 'AddClassify',
  data() {
    return {
      dialogVisible: false,
      dialogTitle: '添加分类',
      loading: false,
      formData: {
        cateName: '',
        parentCateId: '',
      },
      rules: {
        cateName: [{ required: true, message: '请输入', trigger: 'change' }],
        parentCateId: [{ required: true, message: '请选择', trigger: 'change' }],
      },
      firstProList: [],
    };
  },
  watch: {},
  computed: {
    firstPro() {
      const obj = {
        cateName: '顶级',
        id: 0,
      };
      const arr = this.firstProList;
      arr.unshift(obj);
      return arr;
    },
  },
  mounted() {},
  methods: {
    // 获取大类产品
    getFirstPro() {
      appApi.first()
        .then(res => {
          this.firstProList = res.result;
        }).catch(err => {
          console.log(err);
        });
    },
    submitForm() {
      this.$refs.elform.validate(valid => {
        if (valid) {
          this.loading = true;
          const {
            id,
            cateName,
            parentCateId,
          } = this.formData;
          if (this.dialogTitle === '添加分类') {
            api.categoryadd(
              {
                cateName,
                parentCateId,
              },
            )
              .then(res => {
                this.dialogVisible = false;
                this.$message.success(res.message);
                this.$emit('refreshTable');
              })
              .catch(err => {
                console.log(err);
                this.$message.error(err.message);
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            api.categoryedit(
              {
                id,
                cateName,
                parentCateId,
              },
            )
              .then(res => {
                this.dialogVisible = false;
                this.$message.success(res.message);
                this.$emit('refreshTable');
              })
              .catch(err => {
                console.log(err);
                this.$message.error(err.message);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      });
    },
    dialogClosed() {
      this.$refs.elform.resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
