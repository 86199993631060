<template>
  <div>
    <l-toggle :FormButton="FormButton" :openFormBtnShow="false"></l-toggle>
    <div class="tableBox">
      <el-table
        ref="eltable"
        :data="tableData"
        :header-cell-style="{background:'#f2f2f2'}"
        height="100%"
        stripe
        border
        v-loading="tableLoading"
        @selection-change="handleSelectionChange"
      >
        <template slot="empty">
          <img src="@/assets/img/table_noData.png" width="300" height="300" />
        </template>
        <el-table-column type="selection" width="50" align="center"></el-table-column>

        <el-table-column prop="id" label="分类ID" align="center" min-width="80"></el-table-column>
        <el-table-column prop="cateName" label="标题" align="center"></el-table-column>
        <el-table-column prop="parentCateName" label="上级分类" align="center"></el-table-column>
        <el-table-column prop="createTime" label="添加时间" align="center" min-width="150"></el-table-column>
        <el-table-column prop="updateTime" label="最后更新时间" align="center" min-width="150"></el-table-column>
        <el-table-column prop="status" label="当前状态" align="center" width="80">
          <template slot-scope="scope">
            {{ valueToName(filterDictionary('0112'), scope.row.status) }}
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center" fixed="right" width="350">
          <template slot-scope="scope">
            <el-button
              type="success"
              icon="el-icon-edit"
              size="mini"
              @click="edit(scope.row)"
            >编辑
            </el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="delData(scope.row)"
            >删除
            </el-button>
            <el-button
              type="primary"
              icon="el-icon-top-right"
              size="mini"
              @click="OnTheshelf(scope.row, 1)"
            >上架
            </el-button>
            <el-button
              type="info"
              icon="el-icon-bottom-right"
              size="mini"
              @click="OnTheshelf(scope.row, 0)"
            >下架
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px;"
        background
        :disabled="tableLoading"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[30, 50, 100]"
        :page-size="30"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
      >
      </el-pagination>
      <div class="tableFootBtn">
        <el-button
          type="danger"
          size="mini"
          icon="el-icon-delete"
          @click="delSelect"
        >删除选择
        </el-button>
      </div>
    </div>
    <!-- 新增层 -->
    <add-classify
      ref="AddClassify"
      @refreshTable="refreshTable"
    ></add-classify>
  </div>
</template>

<script>
import LToggle from '@/components/LToggle.vue';
import AddClassify from './ProClassify/AddClassify.vue';
import api from '@/api/ProClassifyApi';

export default {
  name: 'ProClassify',
  components: {
    LToggle,
    AddClassify,
  },
  data() {
    return {
      searchFrom: {},
      FormButton: [
        {
          icon: 'el-icon-plus',
          name: '添加分类',
          type: 'primary',
          click: this.addEvent,
        },
      ],
      tableLoading: false,
      tableData: [],
      selectedTableData: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 30,
        total: 300,
      },
    };
  },
  watch: {
    tableLoading(nval) {
      this.FormButton[0].loading = nval;
    },
  },
  created() {
    this.reqTableData();
  },
  methods: {
    delSelect() {
      console.log(this.selectedTableData);
      if (this.selectedTableData.length) {
        const idList = this.selectedTableData.map(val => val.id);
        this.$confirm('确定要<span class="dangerColor">删除所选</span>吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
          showClose: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          dangerouslyUseHTMLString: true,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = '正在删除';
              api.categorydel(
                {
                  idList,
                },
              )
                .then(() => {
                  instance.confirmButtonLoading = false;
                  this.$message.success('删除成功!');
                  this.refreshTable();
                })
                .catch(err => {
                  this.$message.error(err.message);
                })
                .finally(() => {
                  done();
                });
            } else {
              done();
            }
          },
        }).then(() => {}).catch(() => {});
      } else {
        this.$alert('请选择一项！', '提示', { type: 'error' });
      }
    },
    OnTheshelf(row, type) {
      const text = type === 1 ? '上架' : '下架';
      const color = type === 1 ? 'successColor' : 'warningColor';
      this.$confirm(`确定要<span class="${color}">${text}</span>吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: '',
        center: true,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        dangerouslyUseHTMLString: true,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = `正在${text}`;
            done();
            api.categoryshelves(
              {
                id: row.id,
                status: type === 1 ? 1 : 0,
              },
            )
              .then(() => {
                instance.confirmButtonLoading = false;
                this.$message.success(`${text}成功!`);
                this.refreshTable();
              })
              .catch(err => {
                this.$message.error(err.message);
              })
              .finally(() => {
                done();
              });
          } else {
            done();
          }
        },
      }).then(() => {}).catch(() => {});
    },
    delData(row) {
      this.$confirm('确定要<span class="dangerColor">删除</span>吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        dangerouslyUseHTMLString: true,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = '正在删除';
            api.categorydel(
              {
                idList: [row.id],
              },
            )
              .then(() => {
                instance.confirmButtonLoading = false;
                this.$message.success('删除成功!');
                this.refreshTable();
              })
              .catch(err => {
                this.$message.error(err.message);
              })
              .finally(() => {
                done();
              });
          } else {
            done();
          }
        },
      }).then(() => {}).catch(() => {});
    },
    edit(row) {
      this.$refs.AddClassify.dialogVisible = true;
      this.$refs.AddClassify.dialogTitle = '编辑分类';
      this.$nextTick(() => {
        this.$refs.AddClassify.formData = { ...row };
        this.$refs.AddClassify.getFirstPro();
      });
    },
    searchEvent() {
      this.reqTableData();
    },
    addEvent() {
      this.$refs.AddClassify.dialogVisible = true;
      this.$refs.AddClassify.dialogTitle = '添加分类';
      this.$refs.AddClassify.getFirstPro();
    },
    refreshTable() {
      this.pageInfo.pageNum = 1;
      this.reqTableData();
    },
    reqTableData() {
      this.tableLoading = true;
      const {
        pageNum,
        pageSize,
      } = this.pageInfo;
      api.categorylist(
        {
          pageNum,
          pageSize,
        },
      )
        .then(res => {
          this.tableData = res.result.datas;
          this.pageInfo.total = res.result.totalRecords;
        })
        .catch(err => {
          console.log(err);
          this.$message.error(err.message);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    handleSelectionChange(select) {
      this.selectedTableData = select;
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.reqTableData();
    },
    handleCurrentChange(val) {
      this.pageInfo.pageNum = val;
      this.reqTableData();
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
